*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
}
